import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet en forma de copa de 0,5 a 1 cm de diàmetre, que amb el temps s’obre fins arribar a ser plana. Presenta el marge blanquinós i irregular. L’himeni és d’un color taronjós i la superficie externa més blanquinosa amb pèls que engloben partícules de terra. Les espores són el·líptico-fusiformes, de 25-30 x 10-12 micres amb gotes lipídiques.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      